import React, { Component } from "react"
import Helmet from "react-helmet"; 
import { graphql } from 'gatsby';

import MetricChart from "../../components/metricChart/metricChart"
import Newsletter from "../../components/newsletter/newsletter"
import { Learning } from "../../components/learning/learning"
import Hero from "../../components/common/Hero"; 
import Section from "../../components/common/Section"
import Layout from "../../layouts"
import metricStyles from './metrics.module.css';
import { Container, Row, Col} from "react-bootstrap";
import BarChart from "../../components/common/BarChart"; 
import SEO from "../../components/common/SEO"; 

class JobGrowth extends Component {

  render() {    
    return (
      <Layout>
      	<Helmet bodyAttributes={{ class: "metrics-jobs-growth" }} />
        <SEO post={{
          title: "Jobs Created and Jobs Growth By President: Compare Jobs Across US Presidents",
          description: "Ever wondered how many jobs have been created (or lost) under different presidential administrations? See total jobs change for all presidents since 1945!"
        }} />
        <Hero title="Job Market Performance By President" color="page-hero" />

        {/* This Chart for the stock */}
        <Container fluid >          
          <Row>
            <Col className={metricStyles.instructions}>              
                Select an index you want to compare, select the presidents you want to compare and you are good to go!                          
            </Col>            
          </Row>
          <Row>
            <Col>            
              <MetricChart noSelection={true} metric={17} xLabel='Month' data={this.props.data.allPresidentSummary.nodes} />   
            </Col>            
          </Row>
          <Row style={{ marginTop: "50px"}}>
            <Col>
              <Newsletter />
            </Col>
          </Row>                    
          <div style={{ marginTop: "50px", marginBottom: "50px", fontFamily: 'Lato' }}>            
            <Section name="jobs_cumulative_header" header="Cumulative Job Market Performance By President"/>       
                                       
            <BarChart data={this.props.data.allPresidentSummary.nodes} metric='payroll_total' />            
            

            
            <Section name="jobs_cumulative_description"/>                                                            
          </div>

          <div style={{ marginTop: "50px", marginBottom: "50px", fontFamily: 'Lato' }}>            
            <Section name="jobs_annualized_header" header="Annualized Job Market Performance By President" />                          

            <BarChart data={this.props.data.allPresidentSummary.nodes} metric='payroll_annualized' />            
            
            <Section name="jobs_annualized_description"/>                                                  
          </div>
          <Row>
            <Col>
              <Section
                name="metrics_calculation"
                header="How We Calculate Our Presidential Performance Data"
              />
            </Col>
          </Row>

          
          <Row className={'metric-learning-center'}><Col><Learning /></Col></Row>                 
          <Row style={{margin: "50px 0px"}}><Col><Newsletter /></Col></Row>
        </Container>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
query {
  allPresidentSummary(sort: {order: DESC, fields: presidentId}) {
    nodes {
      id
      dataSummary
      gdpSummary
      jobSummary
      keyEvents
      name
      keyPolicies
      party
      stockSummary
      summary
      term
      presidentId
      dow_1_perc
      dow_2_perc
      dow_1_total
      dow_2_total
      dow_1_annualized
      dow_2_annualized
      sandp_2_perc
      sandp_1_perc
      sandp_1_total
      sandp_2_total
      sandp_1_annualized
      sandp_2_annualized
      payroll_1_perc
      payroll_2_perc
      payroll_1_total
      payroll_2_total
      payroll_1_annualized
      payroll_2_annualized
      gdp_1_perc
      gdp_2_perc
      gdp_1_total
      gdp_2_total
      gdp_1_annualized
      gdp_2_annualized
      nasdaq_1_perc
      nasdaq_2_perc
      nasdaq_1_total
      nasdaq_2_total
      nasdaq_1_annualized
      nasdaq_2_annualized
      dow_today_percent
      dow_today_annualized
      dow_annualized
      dow_total
      sandp_today_percent
      sandp_today_annualized
      sandp_annualized
      sandp_total
      payroll_total
      payroll_annualized
      nasdaq_today_percent
      nasdaq_today_annualized
      nasdaq_total
      nasdaq_annualized
      gdp_total        
      gdp_annualized
    }
  }
}
`;

export default JobGrowth;